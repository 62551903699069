<template>
  <div class="thank-you-page">
    <v-container fill-height class="pt-5">
      <v-card flat class="elevation-0 white w-100 rounded pa-4">
        <CategoryTitle :category="category" :showOnMobile="true" />
        <v-card-actions
          class="justify-center"
          :class="email ? 'justify-sm-space-between' : 'justify-sm-end'"
        >
          <v-btn
            color="primary"
            v-if="email && !socialLogin"
            large
            depressed
            @click="resendEmail"
            :disabled="resendDisabled"
          >
            {{ $t("registration.registrationCompleted.resend") }}
          </v-btn>
          <!-- <v-btn
            v-if="!isAuthenticated"
            @click="handleLogin"
            color="primary"
            large
            depressed
          >
            Accedi ora
          </v-btn> -->
          <v-btn to="/" color="primary" large depressed>
            Inizia la spesa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import category from "~/mixins/category";
import login from "~/mixins/login";
import UserService from "~/service/userService";

export default {
  name: "ResetPasswordSent",
  mixins: [category, login],
  props: { showLogin: { type: Boolean, default: false } },
  components: { CategoryTitle },
  data() {
    return {
      email: null,
      socialLogin: null,
      resendDisabled: false
    };
  },
  methods: {
    async handleLogin() {
      await this.doLogin("EbsnCategory");
      if (await this.needAddress()) {
        await this.needTimeslot();
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    },
    async resendEmail() {
      try {
        let response = await UserService.registrationResendEmail(this.email);
        if (response.response && response.response.status === 0) {
          this.resendDisabled = true;
        } else {
          this.resendDisabled = false;
        }
      } catch (err) {
        console.log(err);
      }
    }
  },
  async created() {
    //this.isLoggedIn("EbsnCategory");
    this.email = this.$route.params.email;
    this.socialLogin = this.$route.params.is_social_reg;
  }
};
</script>

<style></style>
